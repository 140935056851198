import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  firstValueFrom, forkJoin,
  lastValueFrom,
  Observable,
  ObservableNotification,
  of
} from "rxjs";
import {environment} from 'src/environments/environment';
import * as _ from 'lodash';
import {camelToSnakeCase} from '../../utils/generic';
import {map, switchMap} from "rxjs/operators";
import {cloneDeep} from "lodash";
import {AuthService} from "../auth.service";
import {v4} from 'uuid';
import {Announce} from "../../app.component";

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  constructor(private httpClient: HttpClient, private authService: AuthService) {
  }

  getData(
    apiPath: string,
    id?: string,
    params: HttpParams = new HttpParams()
  ): Observable<any[]> {
    if (!!id) params = params.set('id', id);

    return this.httpClient.get<any[]>(environment.apiUrl + apiPath, {params});
  }

  getDetail(
    apiPath: string,
    id?: string,
    params: HttpParams = new HttpParams()
  ): Observable<any> {
    //(id)
    if (!!id) params = params.set('id', id);

    return this.httpClient.get<any>(environment.apiUrl + apiPath, {params});
  }

  postData(
    apiPath: string,
    rows: any[],
    params: HttpParams = new HttpParams()
  ): Observable<any> {
    const httpoptions: Object = {
      params,
    };
    return this.httpClient.post<any[]>(
      environment.apiUrl + apiPath,
      rows,
      httpoptions
    );
  }

  postOneData(
    apiPath: string,
    row: any,
    params: HttpParams = new HttpParams()
  ): Observable<any> {
    const httpoptions: Object = {
      params,
    };
    return this.httpClient.post<any>(
      environment.apiUrl + apiPath,
      row,
      httpoptions
    );
  }

  deleteData(apiPath: string, deletedRows: any[], action: string, params: HttpParams = new HttpParams()) {
    params = params.set("a", action);
    const httpoptions: Object = {
      params,
    };
    return this.httpClient.patch<any>(
      environment.apiUrl + apiPath,
      deletedRows,
      httpoptions
    );
  }

  approveData(apiPath: string, idsToApprove: any[]){
    return this.httpClient.post<any>(environment.apiUrl + apiPath + '/approve', idsToApprove, {});
  }

  openData(apiPath: string, idsToApprove: any[]){
    return this.httpClient.post<any>(environment.apiUrl + apiPath + '/open', idsToApprove, {});
  }

  closeData(apiPath: string, idsToApprove: any[]){
    return this.httpClient.post<any>(environment.apiUrl + apiPath + '/close', idsToApprove, {});
  }

  // ITOEM-5: added logic to retrieve notification button
  getNotificationActions(apiPath: string, typeId?: string){
    return this.httpClient.post<any>(environment.apiUrl + apiPath + '/notificationInfo', typeId, {});
  }
  // ITOEM-5: added logic to retrieve notification button
  getManualActions(apiPath: string, typeId?: string){
    return this.httpClient.get<any>(environment.apiUrl + apiPath + '/manualAction');
  }

  exportDataExcel(
    apiPath: string,
    fileType: string,
    params: HttpParams,
    exportRows: any[]
  ) {
    const httpoptions: Object = {
      responseType: 'blob',
      params,
    };
    return this.httpClient.post<any[]>(
      environment.apiUrl + apiPath + '/download/' + fileType,
      exportRows,
      httpoptions
    );
  }

  getSelectionOption(valuesFrom: string) {
    const httpHeader: Object = {
      params: new HttpParams().set('q', valuesFrom),
    };

    return lastValueFrom(
      this.httpClient.get<any>(
        environment.apiUrl + 'util/autocomplete',
        httpHeader
      )
    );
  }

  getSelectionOptionForId(valuesFrom: string, filter: string) {
    const httpHeader: Object = {
      params: new HttpParams().set('q', valuesFrom).set('f', filter),
    };

    return lastValueFrom(
      this.httpClient.get<any>(
        environment.apiUrl + 'util/autocomplete',
        httpHeader
      )
    );
  }

  getAllertByValidity() {
    const params: HttpParams = new HttpParams()
    return this.httpClient.get<{
      oContent: Announce[]
      iResponseCode: number
      sResponseMessage: string
    }>(environment.authApiUrl + 'util/announcements/OEM', {params});
  }

  getVersion() {
    return this.httpClient.get<any>(environment.apiUrl + 'util/version');
  }

  /*postDataDetail(apiUrl: string, params: HttpParams = new HttpParams(), detail: any) {
    if (!!detail) {
      return this.httpClient.post<any[]>(
        environment.apiUrl + apiPath,
        detail
      );
    }
    return of(null)
  }*/

  postDataDetail(
    apiPath: string,
    row: any,
    action?: string
  ): Observable<any[]> {
    //(action)
    /*_.each(row, function(value, key) {
      const oldKey = _.cloneDeep(key)
      key = camelToSnakeCase(key) || key;
      row[key] = value;
      delete row[oldKey];
    });*/

    let params = new HttpParams();
    if (!!action) {
      params = params.set('a', action);
    }

    return this.httpClient.post<any[]>(environment.apiUrl + apiPath, row, {
      params,
    });
  }

  getDataDetail(
    apiPath: string,
    id: string,
    action?: string
  ): Observable<any[]> {
    //(action)
    /*_.each(row, function(value, key) {
      const oldKey = _.cloneDeep(key)
      key = camelToSnakeCase(key) || key;
      row[key] = value;
      delete row[oldKey];
    });*/

    let params = new HttpParams().set('id', id);
    if (!!action) {
      params = params.set('a', action);
    }

    return this.httpClient.get<any>(environment.apiUrl + apiPath, {
      params,
    });
  }

  deleteDetailData(apiUrl: string, idsToDelete: any, params: HttpParams) {
    const httpoptions: Object = {
      params,
    };
    return this.httpClient.patch<any[]>(
      environment.apiUrl + apiUrl,
      idsToDelete,
      httpoptions
    );
  }

  changeStatus(apiUrl: string, id: string, status: string) {
    const params = new HttpParams().set('a', status).set('id', id);
    return this.httpClient.put<any>(
      environment.apiUrl + apiUrl,
      {},
      {params}
    );
  }

  getDocuments(
    apiPath: string,
    type: string,
    params: HttpParams = new HttpParams()
  ): Observable<any[]> {
    params = params.set('type', type);

    return this.httpClient.get<any[]>(environment.apiUrl + apiPath, {params});
  }

  getDocumentsById(
    apiPath: string,
    params: HttpParams = new HttpParams()
  ): Observable<any[]> {
    return this.httpClient.get<any[]>(environment.apiUrl + apiPath, {params});
  }

  uploadDoc(s: string, documentsId: string, file: File) {
    const formData: FormData = new FormData();
    formData.append('document', file, file.name);
    const params = new HttpParams()
      .set('file_type', file.type)
      .set('documents_id', documentsId);
    return this.httpClient.post<any>(environment.apiUrl + s, formData, {
      params: params,
      reportProgress: true,
      headers: new HttpHeaders({timeout: `${20000}`})
    });
  }



  uploadDocInDocs(api: string, documentsId: string, file: File) {
    const formData: FormData = new FormData();
    formData.append('document', file, file.name);
    const params = new HttpParams()
      .set('file_type', file.type)
      .set('file', documentsId);
    return this.httpClient.post<any>(environment.apiUrl + api, formData, {
      params: params,
      reportProgress: true,
      headers: new HttpHeaders({timeout: `${20000}`})
    });
  }

  async uploadFile(api: string, documentsId: string, file: File): Promise<any> {
    const chunkSize = 300 * 1024; // 300KB (in bytes)
    const totalChunks = Math.ceil(file.size / chunkSize);
    const filename = file.name;
    const fileType = file.type;

    for (let chunkNumber = 1; chunkNumber <= totalChunks; chunkNumber++) {
      const start = (chunkNumber - 1) * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);

      await this.uploadChunk(api, chunk, chunkNumber, totalChunks, filename, fileType, documentsId);
    }
  }

  private uploadChunk(api: string, chunk: Blob, chunkNumber: number, totalChunks: number, filename: string, fileType: string, documentsId: string): Promise<any> {
    const formData = new FormData();
    formData.append('file', chunk, filename);
    formData.append('chunkNumber', chunkNumber.toString());
    formData.append('totalChunks', totalChunks.toString());
    formData.append('filename', filename);
    formData.append('file_type', fileType);
    formData.append('documents_id', documentsId);

    return lastValueFrom(this.httpClient.post(environment.apiUrl + api, formData));
  }


  uploadDocZipped(s: string, documentsId: string, file: File, file_type: string) {
    const formData: FormData = new FormData();
    formData.append('document', file, file.name);
    const params = new HttpParams()
      .set('file_type', file_type)
      .set('documents_id', documentsId);
    return this.httpClient.post<any>(environment.apiUrl + s, formData, {
      params,
    });
  }

  deleteDocument(s: string, documentsId: string, documentId: string) {
    const params = new HttpParams()
      .set('documents_id', documentsId)
      .set('document_id', documentId);
    return this.httpClient.delete<any>(environment.apiUrl + s, {params});
  }

  deleteAttachment(id: string) {
    const params = new HttpParams()
      .set('id', id)
    return this.httpClient.delete<any>(environment.apiUrl + 'attachment', {params});
  }

  uploadAttachment(parent: string, parent2: string, parentType: string, path: string, file: File) {
    let params = new HttpParams()
      .set('parent', parent)
      .set('path', path)
      .set('type', parentType)
      .set('parent2', parent2)


    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.httpClient.post<any>(environment.apiUrl + 'attachment', formData, {params});
  }

  importExcel(parent: string, file: File) {
    let params = new HttpParams()

    const httpoptions: Object = {
      responseType: 'blob',
      params,
    };


    const formData: FormData = new FormData();
    formData.append('excelFile', file);

    return this.httpClient.post<any>(environment.apiUrl + parent, formData, httpoptions);
  }

  previewExcel(parent: string, file: File) {
    let params = new HttpParams()

    const httpOptions: Object = {
      params,
    };


    const formData: FormData = new FormData();
    formData.append('excelFile', file);

    return this.httpClient.post<any>(environment.apiUrl + parent, formData, httpOptions);
  }



  reportEndUseRegime(report: string) {
    return this.httpClient.get<any>(environment.apiUrl + report);
  }

  downloadDocument(s: string, documentId: string) {
    let params = new HttpParams().set('id', documentId);

    const httpoptions: Object = {
      responseType: 'blob',
      params,
    };
    return this.httpClient.get<any>(environment.apiUrl + s, httpoptions);
  }


  /*downloadCard(url: string, cardIds: string[]) {
    const httpoptions: Object = {
      responseType: 'blob',
    };
    return this.httpClient.post<any>(
      environment.apiUrl + url + '/',
      cardIds,
      httpoptions
    );
  }*/

  downloadCard(url: string, cardIds: string) {
    const httpoptions: Object = {
      responseType: 'blob',
    };
    return this.httpClient.post<any>(
      environment.apiUrl + url + '/',
      cardIds,
      httpoptions
    );
  }


  deleteDocOrPhoto(doc: string) {
    const params = new HttpParams().set('doc', doc);
    return this.httpClient.delete<any>(environment.apiUrl + "documents/cards/delete/image/doc", {params});
  }


  deleteArchive(s: string, documentsId: string) {
    const params = new HttpParams().set('documents_id', documentsId);
    return this.httpClient.delete<any>(environment.apiUrl + s, {params});
  }

  downloadAllDocument(s: string, DAMAGE_ID: string) {
    let params = new HttpParams().set('id', DAMAGE_ID);
    const httpoptions: Object = {
      responseType: 'blob',
      params,
    };
    return this.httpClient.get<any>(environment.apiUrl + s, httpoptions);
  }

  uploadLoadingCard(url: String, params: HttpParams, file: any) {
    const formData: FormData = new FormData();
    formData.append('document', file);
    return this.httpClient.post<any>(environment.apiUrl + url, formData, {
      params,
    });
  }

  getDataById(apiPath: string, key: string, value: any) {
    let params = new HttpParams().set(key, value);
    return this.httpClient.get<any[]>(environment.apiUrl + apiPath, {params});
  }

  uploadPictures(apiUrl, files: File[], problemId: string) {
    const formData: FormData = new FormData();
    files.forEach((file) => {
      formData.append('pictures', file, file.name);
    });
    let params = new HttpParams().set('problem', problemId);

    return this.httpClient.post<any>(environment.apiUrl + apiUrl, formData, {
      params,
    });
  }

  downloadPictures(apiUrl: string, pictureElement: string[], isZip?: boolean) {
    let params = new HttpParams();
    if (isZip) {
      params = params.set('t', 'zip');
      const httpoptions: Object = {
        responseType: 'blob',
        params,
      };
      return this.httpClient.post<any[]>(
        environment.apiUrl + apiUrl,
        pictureElement,
        httpoptions
      );
    } else {
      params = params.set('t', 'list');
      const httpoptions: Object = {
        params,
      };
      return this.httpClient.post<any[]>(
        environment.apiUrl + apiUrl,
        pictureElement,
        httpoptions
      );
    }
  }

  downloadPicture(apiUrl: string, pictureElement: string) {
    let params = new HttpParams().set('id', pictureElement);
    const httpoptions: Object = {
      responseType: 'blob',
      params,
    };
    return this.httpClient.get<any>(environment.apiUrl + apiUrl, httpoptions);
  }

  deletePicture(apiPath: string, pictureId: string) {
    let params = new HttpParams().set('id', pictureId);
    const httpoptions: Object = {
      params,
    };

    return this.httpClient.delete<any>(environment.apiUrl + apiPath, {
      params,
    });
  }

  getSelectionOptionWithFilter(valuesFrom: string, s: string) {
    const httpHeader: Object = {
      params: new HttpParams().set('q', valuesFrom).set('f', s),
    };

    console.log(valuesFrom, s)

    return lastValueFrom(
      this.httpClient.get<any>(
        environment.apiUrl + 'util/autocomplete',
        httpHeader
      )
    );

  }

  delete(utilProblem: string, strings: string[]) {
    return this.httpClient.post<any>(environment.apiUrl + utilProblem, strings);
  }

  getLanguage(lang: string) {
    const httpHeader: Object = {
      params: new HttpParams().set('l', lang),
    };
    return this.httpClient.get<any>(
      //environment.apiUrl + 'util/lang',
      environment.apiUrl + 'masterdata/translation',
      httpHeader
    ).pipe(
      map(res => {
        if (res && res['oContent'] && res['oContent']['content']) {
          return JSON.parse(res['oContent']['content'])
        }
        return res
      })
    );
  }

	getAvailableLanguagesAndTranslations() {
    const httpHeader: Object = {
      params: new HttpParams()
    };
    return this.httpClient.get<any>(
      //environment.apiUrl + 'util/lang',
      environment.apiUrl + 'masterdata/translation',
      httpHeader
    ).pipe(
      map(res => {

        let toReformat = cloneDeep(res)

        if (toReformat && toReformat['oContent']) {
          return toReformat['oContent'].map(lang => {
            if (lang['content']) {
              return {
                id: lang.id,
                content: JSON.parse(lang.content.replace(/\\"/g, '\''))
              }
            }
            return lang
          })
        }
        return toReformat
      })
    );
  }

	getAvailableLanguages()
	{
		const httpHeader: Object = {
			params: new HttpParams()
		};
		return this.httpClient.get<Array<string>>(
			environment.apiUrl + 'masterdata/translation/available-languages',
			httpHeader
		).pipe(
			map(res =>
				res["oContent"]
			)
		);
	}

  setLanguage(lang: string, content: any) {
    const httpHeader: Object = {
      params: new HttpParams().set('l', lang),
    };
    return this.httpClient.post<any>(
      //environment.apiUrl + 'util/lang',
      environment.apiUrl + 'masterdata/translation',
      content,
      httpHeader
    )
  }

  /**
   * [MB 14/10/2024] ITEUR - 83: create a new language from an existing one
   * */
  setLanguageTranslation(lang: string, langToCopy: string) {
    const httpHeader: Object = {
      params: new HttpParams().set('l', lang).set('lc', langToCopy),
    };
    return this.httpClient.post<any>(
      environment.apiUrl + 'masterdata/language-translation/set',
      null,
      httpHeader
    )
  }

  deleteLanguage(lang: string) {
    const httpHeader: Object = {
      params: new HttpParams().set('l', lang),
    };
    return this.httpClient.delete<any>(
      //environment.apiUrl + 'util/lang',
      environment.apiUrl + 'masterdata/translation',
      httpHeader
    )
  }


  createDamageOTM(
    apiPath: string,
    id: string,
    sWh: string,
    rWh: string,
    damageType: string,
    sendingDate: string,
    receivingDate: string,
    truckPlate: string,
    trailerPlate: string,
    carrierId: string,
    wagon: string,
    container: string,
    deliveryModeId: string,
    delieryTypeId: string,
    despQty: number,
  ): Observable<any[]> {
    //(action)
    /*_.each(row, function(value, key) {
      const oldKey = _.cloneDeep(key)
      key = camelToSnakeCase(key) || key;
      row[key] = value;
      delete row[oldKey];
    });*/

    let params = new HttpParams().set('id', id)
      .set('sWh', sWh)
      .set('rWh', rWh)
      .set('damageType', damageType)
      .set('sendingDate', sendingDate)
      .set('receivingDate', receivingDate)
      .set('truckPlate', truckPlate)
      .set('trailerPlate', trailerPlate)
      .set('carrierId', carrierId)
      .set('wagon', wagon)
      .set('container', container)
      .set('deliveryModeId', deliveryModeId)
      .set('delieryTypeId', delieryTypeId)
      .set('despQty', despQty)
    ;

    return this.httpClient.get<any>(environment.apiUrl + apiPath, {
      params,
    });
  }

  createDamage(
    apiPath: string,
    id: string
  ): Observable<any[]> {
    //(action)
    /*_.each(row, function(value, key) {
      const oldKey = _.cloneDeep(key)
      key = camelToSnakeCase(key) || key;
      row[key] = value;
      delete row[oldKey];
    });*/

    let params = new HttpParams().set('id', id)

    return this.httpClient.get<any>(environment.apiUrl + apiPath, {
      params,
    });
  }

  changeStatusScorecard(
    apiPath: string,
    id: string
  ): Observable<any[]> {
    //(action)
    /*_.each(row, function(value, key) {
      const oldKey = _.cloneDeep(key)
      key = camelToSnakeCase(key) || key;
      row[key] = value;
      delete row[oldKey];
    });*/

    let params = new HttpParams().set('id', id);

    return this.httpClient.get<any>(environment.apiUrl + apiPath, {
      params,
    });
  }

  moveDamageFile(utilMoveDamage: string) {
    return this.httpClient.get<any>(environment.apiUrl + utilMoveDamage);
  }

  moveProblemFile(utilMoveProblem: string) {
    return this.httpClient.get<any>(environment.apiUrl + utilMoveProblem);
  }

  moveCardFile(utilMoveCard: string) {
    return this.httpClient.get<any>(environment.apiUrl + utilMoveCard);
  }

  moveOtherFile(utilMoveFile: string) {
    return this.httpClient.get<any>(environment.apiUrl + utilMoveFile);
  }

}
